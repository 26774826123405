onDomReady(() => {
  if (typeof window.plausible !== "function") return;

  document.querySelectorAll(".banner-link").forEach((el) => {
    let origOnClick = el.onclick;
    el.onclick = () => {
      window.plausible("PhoneClick", {
        callback: origOnClick,
        props: { phone: el.textContent.trim() }
      });
    };
  });
});

function onDomReady(fn) {
  if (document.readyState != "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}
